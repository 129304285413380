// libraries
import valueInvoke from "@splitfire-agency/raiden-library/dist/libraries/utils/valueInvoke";

const defaultWhitelist = {
  "data.username": true,
  "data.password": true,
  "data.remember": true,
  "data.user_type": true,
  "data.code": true,
  "data.recovery_code": true,
  "data.RelayState": true,
  "data.SAMLRequest": true,
};

export default function authLoginSerialize({
  fields,
  authenticationType,
  whitelist = defaultWhitelist,
}) {
  whitelist = valueInvoke(whitelist, defaultWhitelist);

  return {
    SAMLRequest: whitelist["data.SAMLRequest"]
      ? fields?.data?.SAMLRequest
      : undefined,
    RelayState: whitelist["data.RelayState"]
      ? fields?.data?.RelayState
      : undefined,
    data: {
      code:
        authenticationType === "2-factor" && whitelist["data.code"]
          ? fields.data.code
          : undefined,
      recovery_code:
        authenticationType === "2-factor-recovery" &&
        whitelist["data.recovery_code"]
          ? fields.data.recovery_code
          : undefined,
      username: whitelist["data.username"] ? fields?.data?.username : undefined,

      password: whitelist["data.password"] ? fields?.data?.password : undefined,

      remember: whitelist["data.remember"] ? fields?.data?.remember : undefined,

      user_type: whitelist["data.user_type"]
        ? fields?.data?.user_type
        : undefined,
    },
  };
}
