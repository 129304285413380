// deps
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormLabel,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Link,
  Stack,
  useBoolean,
  useToast,
} from "@chakra-ui/react";
import NextLink from "next/link";
import { useEffect, useRef, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { IoEyeOffOutline, IoEyeOutline } from "react-icons/io5";
import { FormattedMessage, useIntl } from "react-intl";
import { useRouter } from "next/router";

// components
import FormControlRHF from "@raiden/library-ui/components/ReactHookForm/FormControlRHF";
import { USERS_USER_TYPE_VALUE_ADMIN } from "@raiden/library-ui/constants/users";
import ApiErrorMessage from "../../../components/ApiErrorMessage";

// constants
import { useApiError } from "../../../containers/ApiError";

// hooks
import useAuth from "@raiden/library-ui/hooks/useAuth";
import useApiFetcher from "@raiden/library-ui/hooks/useApiFetcher";
import useRequest from "@../../../hooks/useRequest";

// utils
import generateAdminPath from "@raiden/library-ui/libraries/utils/generateAdminPath";
import generateApiUri from "@raiden/library-ui/libraries/utils/generateApiUrl";
import formRedirect from "@raiden/library-ui/libraries/utils/formRedirect";

// normalizers
import { authLoginSerialize } from "@raiden/library-ui/normalizers/auth";

// containers
import ChallengeApp from "./ChallengeApp";
import ChallengeEmail from "./ChallengeEmail";
import ChallengeSms from "./ChallengeSms";

/**
 * @typedef {object} SamlSettings
 * @property {{[key: string]: string}} data
 * @property {boolean} saml_authentication_required
 * @property {string} next
 */

/**
 * @typedef {"standard" | "2-factor" | "2-factor-recovery"} AuthenticationType
 */
/**
 * @typedef {"app" | "sms" | "email"} AuthenticatorMethod
 */
/**
 * @typedef {object} AuthenticatorChallenge
 * @property {string} challenge_id
 * @property {string} method
 * @property {string} [email]
 * @property {string} [phone_number]
 */
function Form() {
  const router = useRouter();

  const codeInputRef = useRef(/** @type {HTMLElement | null} */ (null));
  const verificationCodeInputRef = useRef(
    /** @type {HTMLElement | null} */ (null),
  );

  const intl = useIntl();

  const [isPasswordVisible, { toggle: togglePasswordVisibility }] =
    useBoolean(false);

  const [authenticationType, setAuthenticationType] = useState(
    /** @type {AuthenticationType} */ ("standard"),
  );

  const [challenge, setChallenge] = useState(
    /** @type {AuthenticatorChallenge | null} **/ (null),
  );

  const [isLoading, setIsLoading] = useState(false);

  const [isVerificationCodeSent, setIsVerificationCodeSent] = useState(false);

  const { request } = useRequest();

  const apiFetcher = useApiFetcher();

  const toast = useToast();

  const { login, userAdmin } = useAuth();

  const { resolveApiError, resetApiErrors } = useApiError();

  const form = useForm({
    defaultValues: {
      data: {
        username: "",
        password: "",
        remember: false,
        code: "", // 2-factor
        recovery_code: "", // 2-factor-recovery
        user_type: USERS_USER_TYPE_VALUE_ADMIN,
        SAMLRequest: router.query.SAMLRequest,
        RelayState: router.query.RelayState,
      },
    },
  });
  const { handleSubmit, setValue } = form;

  async function onSubmit(fields) {
    setIsLoading(true);

    if (
      userAdmin?.user_type &&
      USERS_USER_TYPE_VALUE_ADMIN !== userAdmin.user_type
    ) {
      await apiFetcher(
        generateApiUri({
          id: "@auth.logout",
        }),
        {
          method: "POST",
        },
      ).catch(() => {
        toast({
          status: "error",
          title: intl.formatMessage({
            defaultMessage:
              "Une erreur est survenue lors de la tentative de déconnexion.",
          }),
        });
      });
    }

    request(
      generateApiUri({
        id: "@auth.login",
      }),
      {
        method: "POST",
        body: authLoginSerialize({ authenticationType, fields }),
      },
    )
      .then((response) => {
        resetApiErrors();
        if (response?.data?.two_factor_authentication_challenge_required) {
          setAuthenticationType("2-factor");
          setChallenge(response.data);
        } else if (response?.saml_authentication_required) {
          formRedirect({
            url: response.next,
            method: "post",
            data: response.data,
          });
        } else {
          toast({
            id: "loginSuccess",
            title: intl.formatMessage({
              defaultMessage: "Connexion réussie !",
            }),
            status: "success",
            duration: 5000,

            isClosable: true,
          });
          login(response?.data);
        }
      })
      .catch((response) => {
        resolveApiError({ response, form });
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  useEffect(() => {
    if (authenticationType === "standard") {
      setValue("data.code", "");
      setValue("data.recovery_code", "");
    }
    resetApiErrors();
  }, [authenticationType, resetApiErrors, setValue]);

  useEffect(() => {
    setIsVerificationCodeSent(challenge?.method === "app" ? true : false);
  }, [challenge]);

  // auto focus code input
  useEffect(() => {
    if (isVerificationCodeSent) {
      codeInputRef.current?.focus();
    }
  }, [isVerificationCodeSent]);

  // auto focus verification code input
  useEffect(() => {
    if (authenticationType === "2-factor-recovery") {
      verificationCodeInputRef.current?.focus();
    }
  }, [authenticationType]);

  return (
    <>
      <Box w="full">
        <ApiErrorMessage />

        <FormProvider {...form}>
          <form action="#" onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing="1rem">
              {authenticationType === "standard" && (
                <>
                  <FormControlRHF
                    name="data.username"
                    label={intl.formatMessage({
                      defaultMessage: "Votre identifiant de connexion",
                    })}
                    placeholder={intl.formatMessage({
                      defaultMessage: "dupont@example.fr",
                    })}
                    rules={{ required: true }}
                  />

                  <FormControlRHF
                    name="data.password"
                    rules={{ required: true }}
                    render={(field) => (
                      <FormControl
                        isInvalid={field.isInvalid}
                        isRequired={field.isRequired}>
                        <FormLabel>
                          <FormattedMessage defaultMessage="Votre mot de passe" />
                        </FormLabel>

                        <InputGroup>
                          <Input
                            {...field}
                            type={isPasswordVisible ? "text" : "password"}
                            placeholder="••••••••••"
                          />

                          <InputRightElement>
                            <IconButton
                              onClick={togglePasswordVisibility}
                              icon={
                                <Icon
                                  as={
                                    isPasswordVisible
                                      ? IoEyeOffOutline
                                      : IoEyeOutline
                                  }
                                />
                              }
                              aria-label={intl.formatMessage({
                                defaultMessage:
                                  "Afficher/masquer le mot de passe",
                              })}
                              size="sm"
                            />
                          </InputRightElement>
                        </InputGroup>
                      </FormControl>
                    )}
                  />

                  <FormControlRHF
                    name="data.remember"
                    render={(field) => (
                      <FormControl id="remember" isInvalid={field.isInvalid}>
                        <Checkbox {...field}>
                          {intl.formatMessage({
                            defaultMessage: "Se souvenir de moi ?",
                          })}
                        </Checkbox>
                      </FormControl>
                    )}
                  />
                </>
              )}

              {authenticationType === "2-factor" && (
                <>
                  {challenge?.method === "app" && <ChallengeApp />}

                  {challenge?.method === "sms" && (
                    <ChallengeSms
                      challenge={challenge}
                      setIsVerificationCodeSent={setIsVerificationCodeSent}
                    />
                  )}

                  {challenge?.method === "email" && (
                    <ChallengeEmail
                      challenge={challenge}
                      setIsVerificationCodeSent={setIsVerificationCodeSent}
                    />
                  )}

                  <FormControlRHF
                    ref={codeInputRef}
                    name="data.code"
                    label={intl.formatMessage({
                      defaultMessage: "Code de vérification",
                    })}
                    placeholder="XXXXXX"
                    rules={{
                      required: true,
                      onChange: (event) => {
                        if (event.target["value"].length === 6) {
                          handleSubmit(onSubmit)();
                        }
                      },
                    }}
                    isDisabled={!isVerificationCodeSent}
                  />
                </>
              )}

              {authenticationType === "2-factor-recovery" && (
                <FormControlRHF
                  ref={verificationCodeInputRef}
                  name="data.recovery_code"
                  label={intl.formatMessage({
                    defaultMessage: "Code de récupération",
                  })}
                  placeholder="XXXXXXXXXX-XXXXXXXXXX"
                  rules={{
                    required: true,
                    onChange: (event) => {
                      if (event.target["value"].length === 21) {
                        handleSubmit(onSubmit)();
                      }
                    },
                  }}
                />
              )}

              <Button
                type="submit"
                isLoading={isLoading}
                isDisabled={
                  !isVerificationCodeSent && authenticationType === "2-factor"
                }
                colorScheme="brandSecondary">
                {(() => {
                  switch (authenticationType) {
                    case "2-factor":
                      return intl.formatMessage({
                        defaultMessage: "Vérifier le code",
                      });
                    case "2-factor-recovery":
                      return intl.formatMessage({
                        defaultMessage: "Récupérer l'accès",
                      });
                    default:
                      return "Se connecter";
                  }
                })()}
              </Button>

              {authenticationType === "standard" && (
                <Box textAlign="center">
                  <NextLink
                    href={generateAdminPath({ id: "password-recovery" })}
                    passHref={true}>
                    <Link color="blue.500">
                      {intl.formatMessage({
                        defaultMessage: "Mot de passe oublié ?",
                      })}
                    </Link>
                  </NextLink>
                </Box>
              )}

              {authenticationType === "2-factor" && (
                <Button
                  onClick={() => {
                    setAuthenticationType("2-factor-recovery");
                  }}
                  variant="outline"
                  size="sm">
                  <FormattedMessage defaultMessage="Se connecter avec un code de récupération" />
                </Button>
              )}

              {["2-factor", "2-factor-recovery"].includes(
                authenticationType,
              ) && (
                <Button
                  onClick={() => {
                    setAuthenticationType("standard");
                  }}
                  variant="outline"
                  colorScheme="gray"
                  size="sm">
                  <FormattedMessage defaultMessage="Retour" />
                </Button>
              )}
            </Stack>
          </form>
        </FormProvider>
      </Box>
    </>
  );
}

export default Form;
